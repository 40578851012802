.matches {
  width: 100%;
  margin-top: 40px;
  .match-card {
    width: 100%;
    .match-card-header {
      width: 100%;
      display: flex;
      height: 70px;
      background: #f1f1f1;
      border-radius: 3px;
      justify-content: space-between;
      align-items: center;
      padding: 0 0px 0 30px;
      margin-bottom: 8px;
      .match-card-header-col1 {
        h2 {
          font-weight: bold;
          font-size: 18px;
          color: #000000;
        }
      }
      .match-card-header-col2 {
        display: flex;
        align-items: center;
        img {
          height: 70%;
          width: 40px;
        }
        p {
          margin-right: 10px;
          margin-left: 10px;
          font-size: 15px;
          color: #000000;
        }
      }

      .match-card-header-col3 {
        h2 {
          font-weight: bold;
          font-size: 16px;
          color: #000000;
        }
        p {
          font-size: 12px;
          letter-spacing: 0.24em;
          color: #000000;
        }
      }

      .match-card-header-col4 {
        h2 {
          font-weight: bold;
          font-size: 16px;
          color: #434a56;
        }
      }

      .match-card-header-col5 {
        height: 100%;
        div {
          background: #4dc937;
          border-radius: 2px;
          height: 100%;
          width: 109px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
        }
      }
    }
  }
}

.matchTab {
  display: flex;
  margin-top: 50px;
  overflow-x: scroll;
  div {
    margin-right: 10px;
    background: #3d5585;
    border-radius: 2px;
    padding: 7px 10px;
    cursor: pointer;
    p {
      font-weight: 300;
      font-size: 13px;
      color: #ffffff;
      white-space: nowrap;
    }
  }
  div:nth-child(1) {
    background-color: #4dc937;
  }
}

.ticketTable {
  width: 100%;
  margin-top: 20px;
  .ticketTableHead {
    width: 100%;
    height: 60px;
    background-color: #101a2e;
    display: flex;
    align-items: center;
    padding-left: 30px;
    h2 {
      color: white;
    }
  }
}
.tbajd {
  background-color: #f9f9f9;
  margin-top: 40px;
  padding: 50px;
}
.ticketSum {
  display: flex;
  .ticketSumCol1 {
    margin-right: 100px;
    h2 {
      font-weight: bold;
      font-size: 36px;
      color: #000000;
    }
    p {
      font-size: 19px;
      color: #000000;
      margin-top: 20px;
      span {
        color: #4dc937ce;
        display: block;
        margin-top: 10px;
      }
    }
  }

  .ticketSumCol2 {
    margin-top: 30px;
    width: 60%;
    h2 {
      font-weight: bold;
      font-size: 18px;
      color: #000000;
    }
    p {
      font-size: 17px;
      color: #000000;
      margin-bottom: 20px;
      line-height: 32px;
    }
  }
}

.selectTicketDetails {
  margin-top: 60px;
  h2 {
    margin-bottom: 20px;
    font-size: 19px;
  }
  form {
    width: 600px;
    .inputWrapT {
      width: 100%;
      margin-bottom: 20px;
      label {
        color: #3b414d;
      }
      select {
        width: 100%;
        height: 40px;
        border: 1px solid #3b414d;
        border-radius: 5px;
        margin-top: 10px;
      }
    }
  }
}

.matchOverviewTable {
  width: 100%;
  margin-top: 60px;
  .matchOverviewTableHeader {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #4dc937;
    margin-bottom: 20px;
    h2 {
      width: 14%;
      font-size: 15px;
      padding-bottom: 25px;
    }

    p {
      width: 14%;
      font-size: 14px;
      padding-bottom: 25px;
    }
  }
}

.paymentTotal {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: column;
  .paymentTotalCol1 {
    display: flex;
    width: 300px;
    justify-content: space-between;
    border-top: 1px solid #000000;
    padding-top: 20px;
    h2 {
      font-size: 15px;
    }
    h2:last-child {
      color: #4dc937;
    }
  }
}

.paymentTotalbtn {
  width: 300px;
  height: 40px;
  margin-top: 50px;
  button {
    width: 100%;
    height: 100%;
    background-color: #4dc937;
    border-radius: 5px;
    color: white;
  }
}

.successModal {
  width: 400px;
  padding: 30px;
  text-align: center;
  h2 {
    font-size: 38px;
    color: #000000;
    margin-bottom: 16px;
    margin-top: 30px;
  }

  p {
    font-weight: 300;
    font-size: 18px;
    color: #000000;
    margin-bottom: 20px;
  }
  button {
    width: 100%;
    margin-bottom: 10px;
    height: 45px;
    border-radius: 5px;
  }
  .btn1 {
    background: #4dc937;
    color: white;
  }

  .btn2 {
    border: 1px solid #4dc937;
    color: #4dc937;
    background-color: transparent;
  }
}

@media (max-width: 1103px) {
  .matches {
    margin-top: 10px;
    .match-card {
      .match-card-header {
        flex-wrap: wrap;
        height: fit-content;
        padding-right: 10px;
        padding: 20px;
        div {
          margin-bottom: 20px;
        }
        .match-card-header-col5 div {
          height: 30px;
          width: fit-content;
          padding: 10px;
        }
      }
    }
  }
}

@media (max-width: 829px) {
  .tbajd {
    padding: 0px;
    background-color: transparent;
  }
  .ticketSum {
    flex-direction: column;
    .ticketSumCol1 {
      width: 100%;
    }
    .ticketSumCol2 {
      width: 100%;
    }
  }
  .selectTicketDetails {
    form {
      width: 100%;
    }
  }

  // .matchOverviewTableHeader {
  // }
}
