.landingPage-wrap {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;

  .addExtra {
    height: 70px;
    filter: drop-shadow(3px 10px 10px rgba(0, 0, 0, 0.253));
    background-color: white;
    transition: all 400ms ease;
    width: 100%;
  }
  .landingP-navbar {
    width: 100vw;
    height: 70px;
    background-color: white;
    position: fixed;
    top: 0;
    z-index: 4;
    transition: all 400ms ease;
    .landNavbarWrap {
      display: flex;
      justify-content: space-between;
      align-items: center;
      // padding-top: 10px;
      height: 100%;
      width: 100%;
      .landNavbarcol1 {
        ul {
          display: flex;
          align-items: center;
          li {
            cursor: pointer;
            font-weight: 300;
            font-size: 15px;
            margin-right: 25px;
            svg {
              margin-right: 30px;
            }
          }
        }
      }
      .landNavbarcol2 {
        ul {
          display: flex;
          align-items: center;
          li {
            color: #131313;
            font-size: 15px;
            margin-left: 20px;
            transition: all 300ms ease;
            cursor: pointer;
            display: flex;
            button {
              margin-right: 10px;
              padding: 10px 20px;
              color: white;
              border-radius: 3px;
              background-color: transparent;
              color: #131313;
              font-size: 14px;
              transition: all 300ms ease;
            }
            button:nth-child(1) {
              width: 107px;
              height: 40px;
              border: 1px solid #4dc937;
              box-sizing: border-box;
              font-style: normal;
              font-weight: 300;
              font-size: 16px;
              color: #4dc937;
            }

            button:nth-child(2) {
              width: 107px;
              height: 40px;
              background-color: #4dc937;
              box-sizing: border-box;
              font-style: normal;
              font-weight: 300;
              font-size: 16px;
              color: white;
              border: none;
            }
          }
        }
      }
    }
  }
  .landingP-main {
    width: 100%;
    margin-top: 40px;
    background-color: white;
    // overflow: hidden;
    // overflow-y: scroll;
    padding-top: 30px;
  }
}

.footer-main-flex {
  display: flex;
}

.footer-right-flex {
  display: flex;
  width: 70%;
  justify-content: space-between;
}

.footerInputRight {
  position: absolute;
  right: 260px;
  top: 1px;
  z-index: 2;
  cursor: pointer;
  z-index: 2;
  background-color: #ffffff;
  font-weight: 700;
  padding-bottom: 0px;
}

.footerInputWrap {
  width: 100%;
  position: relative;
  z-index: 1;
  margin-bottom: 15px;
  margin-top: 20px;
}

.footerInputLeftIcon {
  position: absolute;
  right: 585px;
  top: 1px;
  z-index: 2;
  cursor: pointer;
}
.footer-input-field {
  text-align: left;
  color: #6c6c6c;
  width: 350px;
  padding: 7px;
  padding-left: 30px;
  background-color: #ffffff;
  outline: none;
  border: none;
  border-bottom: 1px #6c6c6c solid;
}

.footer-newsletter-container {
  margin-top: 60px;
  line-height: 30px;
  h3 {
    font-weight: 600;
    font-size: 17px;
    color: #131313;
    margin-bottom: 10px;
  }
  p {
    font-size: 14px;
    color: #131313;
  }
  input {
    background-color: transparent;
  }
  .footerInputRight {
    background-color: transparent;
    font-size: 12px;
  }
}

.landingFooter {
  margin-top: 50px;
  .footerCol1 {
    background-image: url(../assets/images/footer.jpg);
    height: 370px;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100vw;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-align: start;
    // background-position: center;
    h2 {
      font-style: normal;
      font-weight: bold;
      font-size: 42px;
      color: #ffffff;
    }
    p {
      font-weight: 300;
      font-size: 20px;
      line-height: 29px;
      color: #ffffff;
      margin-top: 10px;
      margin-bottom: 20px;
    }
    button {
      width: 158px;
      height: 42px;
      background: #4dc937;
      border-radius: 5px;
      font-weight: 300;
      font-size: 18px;

      color: #ffffff;
    }
  }

  .footerCol2 {
    height: 230px;
    background: #192233;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
    svg {
      margin-bottom: 40px;
    }
    ul {
      display: flex;
      margin-bottom: 20px;
      li {
        margin-right: 20px;
        font-size: 14px;
        text-align: center;
        color: #ffffff;
        cursor: pointer;
      }
    }
    p {
      font-size: 12px;
    }
  }
}

.authModalWrap {
  position: relative;

  .authModalWrap1 {
    position: fixed;
    width: 400px;
    height: 100vh;
    background-color: white;
    right: 0;
    transform: translateX(0);
    transition: all 400ms ease;
    // filter: drop-shadow(10px 10px 10px grey);
    border-top: 1px solid rgba(128, 128, 128, 0.445);
    padding: 30px;
    overflow: hidden;
    overflow-y: scroll;
    padding-bottom: 160px;
  }
}

.hideAuthModal {
  // animation: hideAuth 200 ease;
  position: fixed;
  width: 400px;
  height: 100vh;
  background-color: white;
  right: 0;
  transform: translateX(400px);
  transition: all 400ms ease;
}

.closeModal {
  display: flex;
  justify-content: flex-end;
  svg {
    width: 20px;
    cursor: pointer;
  }
}

.authHeader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-top: 40px;
  img {
    width: 60px;
    margin-bottom: 10px;
  }
  h2 {
    margin-bottom: 10px;
  }
  p {
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    margin-bottom: 7px;
    color: #000000;
    span {
      color: #4dc937;
    }
  }
}

.authFormModal {
  width: 100%;
  margin-top: 40px;
  .inputWrap {
    width: 100%;
    margin-bottom: 40px;
    input {
      width: 100%;
      height: 30px;
      border: none;
      border-bottom: 1px solid #bfbfbf;
    }
    select {
      width: 100%;
      height: 30px;
      border: none;
      border-bottom: 1px solid #bfbfbf;
    }
  }
  .optionsauth {
    text-align: center;
    p {
      font-size: 12px;
      span {
        color: #4dc937;
      }
    }
  }
  .handleModalSubmit {
    width: 100%;
    height: 50px;
    margin-top: 40px;
    button {
      height: 100%;
      width: 100%;
      background-color: #4dc937;
      color: white;
      border-radius: 5px;
      font-size: 15px;
      font-weight: 600;
    }
  }
}

.mobileShow {
  width: 80vw;
  height: 50vh;
  background-color: white;
  position: absolute;
  top: 71px;
  left: 0;
  filter: drop-shadow(1px 5px 1px rgba(128, 128, 128, 0.438));
  ul {
    padding-top: 20px;
    color: black;
    padding-left: 30px;
    padding-right: 30px;
    li {
      margin-bottom: 20px;
      button {
        height: 40px;
        width: 100%;
        background-color: #4dc937;
        color: white;
        border-radius: 5px;
        font-size: 15px;
        font-weight: 600;
        margin-bottom: 10px;
      }
    }
  }
}

@media (min-width: 767px) {
  .showMenu {
    display: none;
  }
  .mobileShow {
    display: none;
  }
}

@media (max-width: 768px) {
  .hd-mobile {
    display: none;
  }
  .landNavbarcol2 {
    ul {
      display: none !important;
    }
  }
}

@media (max-width: 743px) {
  .landingFooter {
    margin-top: 50px;
    .footerCol1 {
      // background-position: center;
      h2 {
      }
      p {
      }
      button {
      }
    }

    .footerCol2 {
      height: fit-content;
      align-items: flex-start;
      svg {
        margin-bottom: 30px;
        margin-top: 30px;
      }
      ul {
        flex-wrap: wrap;
        margin-bottom: 5px;
        li {
          margin-bottom: 40px;
        }
      }
      p {
        font-size: 12px;
      }
    }
    .copy-footer {
      margin-bottom: 40px;
      line-height: 30px;
    }
  }

  .authModalWrap1 {
    width: 90vw !important;
    z-index: 2222;
  }
}
