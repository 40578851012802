.profileLayout {
  display: flex;
  margin-top: 40px;

  .profileLayoutCol1 {
    width: 20%;
    margin-right: 40px;
    border: 1px solid #e0e0e0;
    height: 400px;
    .header {
      border-bottom: 1px solid #e0e0e0;
      padding: 10px 10px 10px 20px;
      h2 {
        font-size: 12px;
        font-size: 16px;
        font-weight: normal;
        color: #000000;
      }
    }

    ul {
      padding: 10px 10px 10px 20px;
      margin-top: 20px;
      li {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        font-size: 14px;
        color: #000000;
        cursor: pointer;
        svg {
          margin-right: 10px;
        }
      }
    }
  }

  .profileLayoutCol2 {
    width: 75%;
    .userPWrap {
      width: 400px;
      background-color: #ebebeb;
      height: fit-content;
      padding: 30px;
      margin-top: 20px;
      border-radius: 5px;
      input {
        width: 100%;
        height: 30px;
        border: none;
        border-bottom: 1px solid #bfbfbf;
        background-color: transparent;
      }
      select {
        width: 100%;
        height: 30px;
        border: none;
        border-bottom: 1px solid #bfbfbf;
        background-color: transparent;
      }
    }
    .userInputWrap {
      margin-bottom: 20px;
    }
    .submitPbtn {
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      button {
        background-color: #4dc937;
        padding: 15px 25px;
        color: white;
        border-radius: 5px;
      }
    }
  }
}

@media (max-width: 997px) {
  .profileLayout {
    .profileLayoutCol1 {
      width: fit-content;
    }
  }
}

@media (max-width: 745px) {
  .profileLayout {
    flex-direction: column;
    .profileLayoutCol1 {
      width: 100%;
      height: fit-content;
      margin-bottom: 20px;
    }

    .profileLayoutCol2 {
      width: 100%;
      .userPWrap {
        width: 100%;
      }
    }
  }
}
