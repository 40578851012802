body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100vw;
  /* height: ; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: generalFont;
  src: url(./assets/font/Sansation-Regular.ttf);
}

@font-face {
  font-family: LightFont;
  src: url(./assets/font/Sansation-Light.ttf);
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box !important;
  scroll-behavior: smooth;
  -ms-overflow-style: none; /* IE 11 */
  scrollbar-width: none; /* Firefox 64 */
  /* position: relative;
  z-index: 1; */
  font-family: LightFont;
}

::-webkit-scrollbar {
  width: 0;
  opacity: 0;
}

.app-padding {
  padding-left: 80px;
  padding-right: 80px;
}

ul {
  list-style-type: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: generalFont;
}

button {
  cursor: pointer;
  border: none;
}

input,
select {
  outline: none;
  /* padding-left: 0px; */
}

@media (min-width: 1440px) {
  /* hi-res laptops and desktops */
  .app-padding {
    margin-right: auto;
    margin-left: auto;
    width: 1440px;
  }
}

@media (max-width: 600px) {
  .app-padding {
    padding-left: 20px;
    padding-right: 20px;
  }
}
