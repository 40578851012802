.bannerWrap {
  background-image: url(../../assets/images/banner.png);
  width: 100%;
  height: 400px;
  background-size: cover;
  color: white;
  justify-content: center;
  // align-items: center;
  display: flex;
  flex-direction: column;
  h2 {
    font-style: normal;
    font-weight: bold;
    font-size: 64px;
    color: #ffffff;
  }
  p {
    font-weight: 300;
    font-size: 22px;
    line-height: 30px;
    color: #ffffff;
    width: 40%;
    margin-top: 10px;
  }
}

@media (max-width: 600px) {
  .bannerWrap {
    height: fit-content;
    padding-top: 40px;
    padding-bottom: 40px;
    p {
      width: 100%;
    }
    h2 {
      font-size: 50px;
    }
  }
}
