.home-page {
  width: 100%;
  height: calc(100vh - 50px);
  background-image: url(../../assets/images/homeBanner.jpg);
  background-position: bottom;
  background-size: cover;
  position: relative;
  display: flex;
  justify-content: start;
  align-items: center;
  .hero-content {
    .textContent {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      margin-bottom: 100px;
      text-align: start;

      h2 {
        font-weight: 700;
        font-size: 50px;
        color: white;
      }
      p {
        font-weight: 400;
        font-size: 24px;
        line-height: 29px;
        color: white;
        margin-bottom: 30px;
        margin-top: 20px;
      }
      button {
        width: 158px;
        height: 42px;
        background: #4dc937;
        font-weight: 300;
        font-size: 18px;
        color: #ffffff;
        border-radius: 4px;
      }
    }
  }
}
.showOff {
  width: 100%;
  // height: 500px;
  background: #192233;
  border-radius: 7px;
  display: flex;
  justify-content: space-between;
  position: relative;
  top: -200px;
  .showOffCol1 {
    padding: 30px 10px 20px 30px;
    .h21 {
      font-weight: bold;
      font-size: 30px;

      color: #ffffff;
    }
    .p1 {
      font-weight: 300;
      font-size: 18px;
      line-height: 21px;
      color: #ffffff;
      margin-top: 10px;
    }

    .lga {
      display: flex;
      margin-top: 10px;
      span {
        padding: 7px 8px;
        background: #3d5585;
        border-radius: 2px;
        margin-right: 10px;
        color: white;
        font-size: 12px;
      }
      span:nth-child(1) {
        background-color: #4dc937;
      }
    }
    .matchTypeIcon {
      display: flex;
      align-items: center;
      margin-top: 20px;
      .lgwrap {
        width: 60px;
        height: 60px;
        border-radius: 50%;
        background-color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
        }
      }
      p {
        margin-left: 10px;
        color: white;
        font-weight: 300;
        font-size: 18px;
      }
    }

    .lgheaderde {
      display: flex;
      margin-top: 30px;
      div {
        margin-right: 20px;
        display: flex;
        align-items: center;
        border-right: 1px solid white;
        padding-right: 20px;
        cursor: pointer;
        p {
          font-weight: 300;
          font-size: 14px;
          color: #ffffff;
          margin-left: 10px;
        }
        img {
          height: 18px;
        }
      }
    }

    .matchCard {
      width: 100%;
      margin-top: 20px;
      .matchCardChild {
        width: 100%;
        height: 67px;
        background-color: #232e44;
        margin-bottom: 10px;
        display: flex;
        justify-content: space-between;
        div {
          width: 25%;
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 10px;
        }

        .col1 {
          display: flex;
          align-items: center;
          justify-content: center;
          h2 {
            font-size: 12px;
            color: #96a0b4;
          }
        }

        .col2 {
          display: flex;
          flex-direction: row;
          margin-right: 10px;
          h2 {
            margin-right: 10px;
            margin-left: 10px;
            font-size: 14px;
            color: #ffffff;
          }
          img {
            width: 40px;
            height: 40px;
          }
        }

        .col3 {
          display: flex;
          align-items: center;
          justify-content: center;
          h2 {
            font-size: 14px;
            color: #ffffff;
          }
          p {
            color: #a4a4a4;
            font-size: 10px;
          }
        }

        .col4 {
          display: flex;
          align-items: center;
          justify-content: center;
          h2 {
            font-size: 12px;
            color: #96a0b4;
          }
        }

        .col5 {
          display: flex;
          align-items: center;
          justify-content: center;
          button {
            background: #4dc937;
            border-radius: 2px;
            padding: 5px;
            color: white;
          }
        }
      }
    }

    .dmore {
      font-weight: 300;
      font-size: 14px;
      color: #4dc937;
      margin-top: 20px;
      cursor: pointer;
    }
  }
  .showOffCol2 {
    width: 50%;
    height: 100%;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
.clearPosition {
  margin-top: -170px;
  background-color: red;
  // width: 100px;
  // height: 200px;
}

.matchCardSlide {
  display: flex;
  overflow: hidden;
  overflow-x: scroll;
  // width: 90%;
  .card {
    width: 285px;
    // height: 248px;
    border-radius: 5px;
    margin-right: 20px;
    flex: none;
    cursor: pointer;
    // background-color: rgba(128, 128, 128, 0.322);
    img {
      width: 100%;
      height: 60%;
      border-radius: 5px;
    }
    h2 {
      font-style: normal;
      font-weight: bold;
      font-size: 17px;
      color: #000000;
      margin-top: 10px;
    }
    p {
      font-weight: 300;
      font-size: 13px;
      color: #000000;
      margin-top: 10px;
      width: 90%;
    }
  }
}

.hovfsc {
  overflow: hidden;
}

.appHeader {
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  color: #000000;
  margin-bottom: 20px;
  margin-top: 20px;
}

.buyTickHome {
  background-image: url(../../assets//images/tik.png);
  width: 100%;
  background-size: cover;
  height: 196px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 40px;
  margin-bottom: 40px;
  padding: 30px;
  h2 {
    font-weight: bold;
    font-size: 45px;
    color: #ffffff;
  }
  div {
    button {
      padding: 15px 10px;
      margin-left: 20px;
      border-radius: 5px;
      background-color: transparent;
      border: 1px solid #4dc937;
      color: #4dc937;
      background-color: #4dc937;
      color: white;
      border: none;
    }
    button:nth-child(1) {
      background-color: #4dc937;
      color: white;
      border: none;
    }
  }
}

.ticketInfoM {
  width: 500px;
  padding: 20px;
  .mdHeader {
    font-size: 26px;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  p {
    margin-bottom: 20px;
    line-height: 28px;
    font-size: 15px;
  }

  h4 {
    font-size: 14px;
    margin-bottom: 15px;
  }
  ul {
    margin-bottom: 20px;
    list-style-type: disc;
    margin-left: 20px;
    li {
      margin-bottom: 10px;
      font-size: 13px;
    }
  }

  .closeBtn {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    margin-top: 80px;
    button {
      background-color: #4dc937;
      color: white;
      border: none;
      padding: 15px 50px;
      border-radius: 5px;
      outline: none;
    }
  }
}

@media (max-width: 1060px) {
  .showOff {
    flex-direction: column;
    .showOffCol2 {
      display: none;
    }
  }
}

@media (max-width: 700px) {
  .home-page .hero-content .textContent {
    margin-bottom: 150px;
  }
  .showOff {
    width: 100% !important;
    height: fit-content;
    margin-top: 0px;
    .showOffCol1 {
      width: 100%;
      .lgheaderde {
        display: none;
        // div {
        //   margin-bottom: 10px;
        // }
      }

      .lga {
        flex-wrap: wrap;
        span {
          margin-bottom: 20px;
        }
      }

      .matchCard {
        .matchCardChild {
          flex-wrap: wrap;
          height: fit-content;
          div {
            width: auto;
          }
        }
      }
    }
  }
}

@media (max-width: 953px) {
  .buyTickHome {
    flex-direction: column;
    height: fit-content;
    align-items: flex-start;
    div {
      display: flex;
      flex-wrap: wrap;
      button {
        margin-bottom: 10px;
        margin: 0;
        margin-bottom: 10px;
        margin-right: 10px;
      }
    }
  }
}

@media (max-width: 600px) {
  .appHeader {
    margin-top: 0px;
    font-size: 21px;
  }
  .buyTickHome {
    flex-direction: column;
    height: fit-content;
    align-items: flex-start;
    margin-top: 20px;
    h2 {
      margin-bottom: 20px;
    }
    div {
      display: flex;
      flex-wrap: wrap;
      button {
        width: 100%;
      }
    }
  }
}
